import React from "react";
import { IoIosPaperPlane } from "react-icons/io";
import "./header.css";
import img2 from "../../assets/6.png";
import { Link } from "react-router-dom";
import { CTA } from "../../components";

const Header = () => {
  return (
    <>
      <div className="padding__top h-100" id="home">
        <div className="gpt3__header section__padding">
          <div className="gpt3__header-content">
            <h1 className="gradient__text">
              A decentralized infrastructure for DeFi, NFTs and Real Estates
            </h1>
            <p>
              Safe protocol is a decentralized Infrastructure that will cut
              across Decentralized Finance, NFT, Real Estate(Asset Tokenization,
              Digital and physical properties), the Metaverse and much more.
            </p>
            <div className="gpt3__header-content__buttons">
              <Link to="/private-sale" className="gp-icon-button">
                <span>
                  <IoIosPaperPlane />
                </span>
                Presale
              </Link>
            </div>
          </div>
          <div className="gpt3__header-image">
            <img src={img2} alt="hero" />
          </div>
        </div>
      </div>

      <CTA />
    </>
  );
};

export default Header;
