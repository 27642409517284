import React from "react";
import "./footer.css";
import logo from "../../assets/logo.svg";

const boards = [
  {
    name: "Kayode Ayeni",
    job: "Visioneer and Real Estate Investor",
    linkedin: "https://www.linkedin.com/in/kayode-ayeni-75b879129",
    telegram: "/",
    twitter: null,
  },
  {
    name: "Carlos Alvarez",
    job: "Blockchain Expert and Consultant",

    linkedin: "https://www.linkedin.com/in/inspiringcarlos",
    telegram: "/",
    twitter: "https://twitter.com/inspiringcarlo",
  },

  {
    name: "Judah Immanuel",
    job: "Businesss Developer and Consultant",

    linkedin: "https://www.linkedin.com/in/judah-immanuel-73b2963a",
    telegram: "/",
    twitter: null,
  },
];

const Footer = () => {
  return (
    <>
      <h1 className="text-center hidden w-[95%]  lg:w-[90%] px-4 pb-2  text-4xl lg:text-center md:text-5xl lg:text-6xl gradient__text  lg:font-bold  pt-4">
        {" "}
        Meet the SafeProtocol board
      </h1>

      <div className="min-h-[500px] hidden w-[95%] shadow lg:w-[90%] mx-auto rounded-xl  gp-card-bg p-4 px-8 my-8 mb-16">
        <div className="grid-cols-1 lg:grid-cols-3 grid gap-4 mt-8 gap-y-8 lg:gap-y-0 lg:gap-x-8">
          {boards.map((b, i) => {
            return (
              <div key={i} className=" rounded-xl">
                <img
                  src={`/fc/${i + 1}.jpg`}
                  alt="Team Member"
                  height="200"
                  width="200"
                  className="h-[350px] w-[325px] lg:h-[375px] block mx-auto lg:w-[325px] rounded-xl"
                />

                <div className="py-4 px-4 text-white lg:ml-8 mx-auto">
                  <h1 className="text-center lg:text-left text-lg font-bold  p-2 ">
                    {b.name}
                  </h1>

                  <h2 className="text-center lg:text-left text-base   p-2 ">
                    {b.job}
                  </h2>

                  <div className="text-center lg:text-left mx-auto text-xs py-4 px-2 flex flex-row justify-center lg:justify-start space-x-4 items-center text-white ">
                    <a href={b.linkedin}>
                      {" "}
                      <span className="cursor-pointer text-center lg:text-left ">
                        <i className="bi-linkedin"></i> LinkedIn
                      </span>
                    </a>

                    {b.twitter && (
                      <a href={b.twitter}>
                        <span className=" text-center lg:text-left">
                          {" "}
                          <i className="bi-twitter"></i> Twitter{" "}
                        </span>
                      </a>
                    )}

                    {/* <a href={b.telegram}>
                      <span className=" text-center lg:text-left">
                        {" "}
                        <i className="bi-telegram"></i> Telegram{" "}
                      </span>
                    </a> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="gpt3__footer-divider"></div>
      <div className="gpt3__footer section__padding">
        <div className="gpt3__footer-content">
          <div className="gpt3__footer-content_copyright">
            <a href="/#home">
              <img src={logo} alt="" />

              <div className="mt-8 mb-4 flex flex-row justify-start space-x-8">
                <a href="https://twitter.com/safeprotocl_hq">
                  <i className="bi-twitter text-3xl text-white "></i>
                </a>

                <a href="https://www.facebook.com/safeprotocol">
                  <i className="bi-facebook text-3xl text-white "></i>
                </a>

                <a href="https://t.me/safeprotocol">
                  <i className="bi-telegram text-3xl text-white "></i>
                </a>
              </div>
            </a>
            <p>Copyright ©️ 2022 SafeProtocol All Rights Reserved</p>
          </div>
          <div className="gpt3__footer-content_navigation">
            <div>
              <h1 className="gradient__text">Navigation </h1>
              <div className="gpt3__footer-links_container">
                <a href="/#home">Home</a>
                <a href="/#about">About Us</a>
                {/* <a href="private-sale">Presale</a> */}
              </div>
            </div>
            <div>
              <h1 className="gradient__text">About Us </h1>
              <div className="gpt3__footer-links_container">
                {/* <a href="/safeprotocol.pdf">Whitepaper</a> */}
                <a href="/#connect">Community</a>
                <a href="/#connect">Media kit</a>
              </div>
            </div>
          </div>
          <div className="gpt3__footer-content_contact">
            <div>
              <h1 className="gradient__text">Contact us</h1>
              <div className="gpt3__footer-links_container">
                <p>
                  Business:{" "}
                  <a mailto="info@safeprotocol.co" href="/">
                    info@safeprotocol.co
                  </a>
                </p>
                <p>
                  Business:{" "}
                  <a mailto="info@safeprotocol.co" href="/">
                    info@safeprotocol.co
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
