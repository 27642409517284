import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Web3 from "web3";
import StoreContext from "../../store/AppStore";
import { useContext, useState } from "react";
import presaleABI from "../../lib/presale.abi.json";
import config from "../../lib/config";

const schema = Yup.object().shape({
  to: Yup.string()
    .test("test-addr", "Invalid address", (v) => {
      return Web3.utils.isAddress(v);
    })
    .label("Address")
    .required(),
  amount: Yup.number()
    .min(0, "Too small!")
    .label("Amount")
    .required()
    .typeError("Not a number!"),
});

function Cave(props) {
  const { state, dispatch, auth } = useContext(StoreContext);

  function handleSubmit(values) {
    if (!auth) {
      dispatch({ type: "SHOW_CONECT_WALLET_POPUP" });
      return;
    }

    let inWei = 0;
    if (+values.amount > 0) {
      inWei = Web3.utils.toWei(values.amount);
    }

    dispatch({ type: "SHOW_NET_LOADER" });

    const presaleContract = new state.w3.web3.eth.Contract(
      presaleABI,
      config.presaleContract.mainnet
    );

    presaleContract.methods
      .withdraw(inWei, values.to)
      .send({ from: state.w3.address }, (err, txHash) => {

        if (err) {
          dispatch({
            type: "SHOW_INFO_POPUP",
            payload: {
              infoObject: {
                title: "Transaction Error",
                level: "error",
                body: err.message,
                showHelpLink: true,
              },
            },
          });

          return;
        }

        dispatch({
          type: "SHOW_INFO_POPUP",
          payload: {
            infoObject: {
              title: "Transaction Sent",
              level: "info",
              body: "Transaction has been approved and sent by your wallet. You will receive confirmation when transaction is completed.",
              showHelpLink: true,
            },
          },
        });

        dispatch({ type: "HIDE_NET_LOADER" });
      })
      .on("receipt", () => {
        dispatch({
          type: "SHOW_INFO_POPUP",
          payload: {
            infoObject: {
              title: "Transaction Successful",
              level: "success",
              body: "Your withdrawal transaction has been completed 😍. Please check your balance to confirm ✨.",
              showHelpLink: true,
            },
          },
        });
      })
      .on("error", (err) => {
        dispatch({ type: "HIDE_NET_LOADER" });

        dispatch({
          type: "SHOW_INFO_POPUP",
          payload: {
            infoObject: {
              title: "Transaction Error",
              level: "error",
              body: err.message,
              showHelpLink: true,
            },
          },
        });
      });
  }

  return (
    <div className="w-[100%]">
      <div className="gradient__bg w-[100%]">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={schema}
          initialValues={{
            to: "",
            amount: "",
          }}
        >
          {({ isValid, isSubmitting }) => {
            return (
              <Form className="pt-64 px-4 w-[100%] pb-16">
                <Field
                  className="min-h-[60px] block bg-white w-[100%] px-4 rounded max-w-md mx-auto outline-none"
                  type="text"
                  name="amount"
                  placeholder="Amount in BNB"
                />

                <p className="text-sm md:text-base w-[100%] block mx-auto text-center text-red-500 font-normal mb-4 pl-6 first-letter:uppercase lowercase ">
                  <ErrorMessage name="amount"></ErrorMessage>{" "}
                </p>

                <>
                  <Field
                    type="text"
                    className="min-h-[60px] block bg-white w-[100%] px-4 rounded max-w-md mx-auto outline-none"
                    name="to"
                    placeholder="To"
                  />

                  <p className="text-sm md:text-base w-[100%] block mx-auto text-center text-red-500 font-normal mb-4 pl-6 first-letter:uppercase lowercase ">
                    <ErrorMessage name="to"></ErrorMessage>
                  </p>
                </>

                {/* <div
                  style={{ fontFamily: "Manrope" }}
                  className="w-[100%] mx-auto"
                >
                  <div className="bg-black w-[100%] text-center px-4 lg:w-[100%] py-2 lg:py-2 rounded-lg text-white font-bold  text-2xl"></div>
                </div> */}

                <input
                  type="submit"
                  value="Withdraw"
                  className="mx-auto cursor-pointer mt-8 mb-6  block py-3 w-[40%] text-md text-white outline-none bg-sky-500"
                  onClick={async (e) => {}}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

Cave.propTypes = {};

export default Cave;
