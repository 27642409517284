export default function reducer(state, action) {
    switch (action.type) {
      case "SET_BNB":
        return { ...state, bnb : action.payload.obj };
        break;
  
     
      default:
        return state;
    }
  }
  