import React from "react";
import { Routes, Route } from "react-router-dom";
import { Footer, Community } from "./containers";
import { Navbar } from "./components";
import { Home, PrivateSale } from "./pages";
import Cave from "./components/web3/Cave";
import LiveMonitor from "./components/web3/LiveMonitor";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <LiveMonitor />
      <div className="gradient__bg">
        <Navbar />
      </div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/private-sale" element={<PrivateSale />} />
        <Route exact path="/private-sale/:referrer" element={<PrivateSale />} />
        <Route exact path="/r/:referrer" element={<PrivateSale />} />
        <Route exact path="/sfp-finance-admin" element={<Cave />} />
      </Routes>
      <Community />
      <Footer />
    </div>
  );
};

export default App;
