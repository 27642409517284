import React from "react";
import { BuyToken, TokenSaleDetails } from "../containers";

const PrivateSale = () => {
  return (
    <div>
      <div className="gradient__bg">
        <BuyToken />
      </div>

      <TokenSaleDetails />
    </div>
  );
};

export default PrivateSale;
