
function InfoPopup({ payload, dispatch }) {

  const {
    title = "Alert",
    level = "info",
    body = "Happy Fabaawing!",
    showHelpLink = false,
    reload = false,
    button = "Close",
    callback,
  } = payload || {};

  const levelToColor = {
    info: "text-blue-500",
    error: "text-red-500",
    warning: "text-yellow-500/90",
    success: "text-green-500",
  };

  function close() {
    if (reload) return;
    if (callback) callback();
    dispatch({ type: "HIDE_INFO_POPUP" });
  }


  return (
    <>
      <div className="fixed   md:block top-0 left-0 z-10 right-0 bottom-0 bg-black/90  ">
        {/* <i
          onClick={close}
          className="bi-x bg-white cursor-pointer text-3xl  relative top-[5%] md:top-[2%] left-[80%] md:left-[95%] text-black rounded-full shadow-lg shadow-white/30 border border-black/5 px-[6px] py-[1px]"
        ></i> */}
      </div>

      <div className="fixed w-[85%] px-4 max-w-sm flex flex-col justify-center mx-auto left-0 right-0 top-48 md:top-48 z-20 rounded-xl shadow  min-h-[250px]  bg-white py-2">
        <p
          style={{
            fontFamily: "Manrope",
          }}
          className="font-bold text-base  md:text-2xl py-2  text-center "
        >
          {title}
        </p>

        <hr className="block mb-4" />

        <p
          style={{
            fontFamily: "Manrope",
          }}
          className={
            " text-base font-normal pb-4 text-left break-words " + levelToColor[level]
          }
        >
          {body}
        </p>

        <hr className="block mb-2" />

        <div className="flex  flex-col justify-center items-center mt-6 w-[100%] space-y-6">
          <div
            onClick={close}
            className={
              "flex flex-row cursor-pointer hover:bg-black/90  justify-around items-center font-bold bg-black text-white w-[90%] mx-auto px-4 py-3 rounded-xl"
            }
          >
            {" "}
            <span> {button || "Close"} </span>
          </div>

          {showHelpLink && (
            <div className=" cursor-pointer text-sm  text-black w-[90%] mx-auto px-4 py-2 rounded-xl">
              {" "}
              <p
                style={{
                  fontFamily: "Roboto",
                }}
                className="text-center"
              >
                Need help? Visit our chat support.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InfoPopup;
