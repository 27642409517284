import React from "react";
import { FaCoins, FaTelegram } from "react-icons/fa";
import "./cta.css";
import { Link } from "react-router-dom";
import useCounter from "../web3/useCounter";
import config from "../../lib/config";

// export function fmt(x) {
//   x = String(x);

//   if (x.length > 1) {
//     return x;
//   } else {
//     return `0${x}`;
//   }
// }

const CTA = () => {
  const ts = useCounter(config.launchTime);

  return (
    <div className="gpt3__cta section__padding">
      <div className="gpt3__cta-content">
        {/* <p>Join our Presale Round 1</p> */}
        <h3> Launching Soon!</h3>
      </div>

      {!ts.elapsed && (
        <div
          style={{ fontFamily: "Manrope" }}
          className="w-[100%] lg:w-[40%]  lg:ml-8"
        >
          <div className="bg-black w-[100%] text-center px-4 lg:w-[100%] py-4 lg:py-2 rounded-lg text-white font-bold  text-3xl">
            {/* <span> { fmt(ts.date)} d : </span> */}
            <span> {ts.display} </span>
          </div>
        </div>
      )}

      <div className="gpt3__cta-btn ">
        <a
          style={{ background: "#040c18" }}
          href="https://t.me/safeprotocol"
          className="gp-icon-button "
        >
          <span style={{ paddingTop: "5px", paddingRight: "0.3rem" }}>
            <FaTelegram />
          </span>
          Join our telegram
        </a>
      </div>
    </div>
  );
};

export default CTA;
