import React from "react";
import { Feature } from "../../components";
import "./about.css";

const WhatGPT3 = () => {
  return (
    <div className="id__positioning" id="about">
      <div className="gpt3_about section__margin gp-card-bg">
        <div className="gpt3_about-feature">
          <Feature
            title="About Us"
            text="Built Initially on the BInance Smart chain and with the intention to expand in future to other Chains, Safe protocol recognizes the importance of a highly accessible blockchain that will allow more people to enter the world of DeFi and NFTs with ease and smoothly participate in a decentralized network."
          />
        </div>
        <div className="gpt3_about-heading">
          <h1 className="gradient__text">
            The possibilities are beyond your imagination
          </h1>
          <p>Our Ecosystem</p>
        </div>
        <div className="gpt3_about-container">
          <Feature
            title="SFP Token"
            text="The Native Utility and Governance token of the Safe protocol ecosystem."
          />
          <Feature
            title="SafeHome"
            text="A decentralized and tokenized real estate marketplace and Infrastructure built on blockchain."
          />
          <Feature
            title="SafeDAO"
            text="The SafeDAO is the organization that governs several aspects of Safe Protocol including SFP token utility and distribution."
          />
        </div>
      </div>
    </div>
  );
};

export default WhatGPT3;
