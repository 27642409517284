import React, { useState, useContext, useEffect } from "react";
import "./buyTokensForm.css";
import * as Yup from "yup";
import { Field, ErrorMessage, Form, Formik } from "formik";
import cn from "classnames";
import Web3 from "web3";
import StoreContext from "../store/AppStore";
import { checkIsValidReferrer, buyPresale } from "../lib/presale";
import { useParams } from "react-router-dom";
import config from "../lib/config";
import {
  shortAddr,
  fallbackCopyTextToClipboard,
} from "../components/web3/UserPanel";
import { fetchBNBPrice } from "../lib/presale";
import { networkIdToNetworkInfoMap } from "../lib/wallet";
import useCounter from "../components/web3/useCounter";

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(30, "Too small! (min of $30)")
    .max(200000, "Too big!")
    .required("You haven't told us how much you wish to buy.")
    .typeError("Uh, that's not a number."),

  referrer: Yup.string().test(
    "is-bnb-address",
    "That's not a valid wallet address.",
    (value) => {
      return Web3.utils.isAddress(value);
    }
  ),
});

const BuyTokensForm = () => {
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const { state, dispatch, auth, broadcast } = useContext(StoreContext);
  const [validating, setValidating] = useState(false);
  const { referrer = String(process.env.REACT_APP_OWNER).trim() } = useParams();
  const ts = useCounter(config.launchTime);

  const referrerIsValidAddress = Web3.utils.isAddress(referrer);

  const coinName = auth
    ? `${
        networkIdToNetworkInfoMap[state.w3.networkId]
          ? networkIdToNetworkInfoMap[state.w3.networkId].coin
          : "UNKNOWN"
      }`
    : "BNB";

  var copyElementText = () => {
    navigator.clipboard
      .writeText(`${config.siteUrl}/r/${state.w3.address}`)
      .then(
        () => {
          setShowLinkCopied(true);
          setTimeout(() => {
            setShowLinkCopied(false);
          }, 2000);
        },

        () => {
          fallbackCopyTextToClipboard(
            `${config.siteUrl}/r/${state.w3.address}`
          );

          setShowLinkCopied(true);
          setTimeout(() => {
            setShowLinkCopied(false);
          }, 2000);
        }
      );
  };

  useEffect(() => {
    // if (!auth) return;

    // console.log(state.data.bnb && state.data.bnb);

    async function exec() {
      let proceed = true;
      const saved = localStorage.getItem("xsafeprotocolbnbvaultx");

      if (saved) {
        const vault = JSON.parse(saved);

        // console.log("From Vault: ", vault)

        const now = Date.now() / 1000;
        const threshold = 45;
        const delta = now - vault.t2;

        if (delta < threshold) {
          proceed = false;
          dispatch({ type: "SET_BNB", payload: { obj: vault } });
        } else {
          // console.log("Threshold exceeded: ", delta , "s")
        }
      }

      if (proceed) {
        console.log("Proceeding with BNB price fetching...");
        const result = await fetchBNBPrice(dispatch);

        if (result) {
          localStorage.setItem(
            "xsafeprotocolbnbvaultx",
            JSON.stringify({ ...result, t2: Date.now() / 1000 })
          );
        }
      } else {
        // console.log("Not proceeding with BNB price fetching...");
      }
    }

    exec();
  }, [auth]);

  async function handleSubmit(values, { resetForm }) {
    // alert(JSON.stringify(values))

    if (!ts.elapsed) return;

    if (!auth) {
      dispatch({ type: "SHOW_CONNECT_WALLET_POPUP" });
      return;
    }

    if (state.w3.address.toLowerCase() === values.referrer.toLowerCase()) {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Referral Error",
            level: "warning",
            body: "You cannot refer yourself, please use a referral link from someone else.",
          },
        },
      });

      return;
    }

    if (state.w3.networkId !== 56 && state.w3.networkId !== 97) {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Network Error",
            level: "warning",
            body: "Please switch to the Binance Smartchain Mainnet network in your wallet and reconnect to continue.",
          },
        },
      });

      return;
    }

    await buyPresale(
      values.amount,
      values.referrer,
      state.w3.web3,
      state.w3.address,
      dispatch,
      state.w3.networkId,
      resetForm,
      state.data.bnb.price,
      broadcast
    );
  }

  return (
    <div className="gpt3__buyTokenForm">
      <div className="gpt3__buyTokenForm-card gp-card-bg">
        <h1>Buy Token</h1>
        <div className="gpt3__buyTokenForm-gradient-bar"></div>
        <Formik
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={{
            amount: "",
            referrer: referrerIsValidAddress ? referrer : "",
            validating: "",
          }}
        >
          {({ isValid, submitForm, isSubmitting, setFieldError, values }) => {
            return (
              <Form className="relative">
                <Field
                  className=""
                  type="text"
                  name="amount"
                  placeholder="Amount (USD)"
                />

                <i className="bi-currency-dollar  right-6 top-[10%] absolute text-white text-xl"></i>

                <b className="text-xs md:text-sm text-white font-normal text-left pl-6 first-letter:uppercase lowercase ">
                  Minimum of $30
                </b>

                <b className="text-sm md:text-base text-red-500 font-normal text-left pl-6 first-letter:uppercase lowercase ">
                  <ErrorMessage name="amount"></ErrorMessage>{" "}
                </b>

                <div
                  style={{ fontFamily: "Manrope" }}
                  className="w-[100%]  rounded text-white text-center flex flex-row  bg-[#000000]/40 text-base md:text-lg justify-evenly items-center"
                >
                  <b className="w-[50%] py-3 font-normal border-r border-[#042C54]">
                    {state.data.bnb && values.amount
                      ? (values.amount / state.data.bnb.price).toFixed(5)
                      : 0}{" "}
                    {coinName}
                  </b>
                  <b className="w-[50%] font-normal py-3 border-l border-[#042C54]">
                    {values.amount ? values.amount / 0.015 : 0} SFP
                  </b>
                </div>

                {!referrerIsValidAddress && (
                  <>
                    <Field type="text" name="referrer" placeholder="Referrer" />

                    <b className="text-sm md:text-base text-red-500 text-left pl-6 first-letter:uppercase lowercase font-normal">
                      <ErrorMessage name="referrer"></ErrorMessage>
                    </b>
                  </>
                )}

                {!ts.elapsed && (
                  <div
                    style={{ fontFamily: "Manrope" }}
                    className="w-[100%] mx-auto"
                  >
                    <div className="bg-black w-[100%] text-center px-4 lg:w-[100%] py-2 lg:py-2 rounded-lg text-white font-bold  text-2xl">
                      {/* <span> { fmt(ts.date)} d : </span> */}
                      <span> {ts.display} </span>
                    </div>
                  </div>
                )}

                {ts.elapsed && (
                  <input
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!ts.elapsed) return;

                      if (!isValid || isSubmitting || validating) return;

                      if (!auth) {
                        dispatch({ type: "SHOW_CONNECT_WALLET_POPUP" });
                        return;
                      }

                      if (
                        state.w3.networkId !== 56 &&
                        state.w3.networkId !== 97
                      ) {
                        dispatch({
                          type: "SHOW_INFO_POPUP",
                          payload: {
                            infoObject: {
                              title: "Network Error",
                              level: "warning",
                              body: "Please switch to the Binance Smartchain Mainnet network in your wallet and reconnect to continue.",
                            },
                          },
                        });

                        return;
                      }

                      setValidating(true);

                      const isValidReferrer = await checkIsValidReferrer(
                        state.w3.web3,
                        values.referrer,
                        state.w3.address,
                        dispatch
                      );
                      setValidating(false);

                      if (!isValidReferrer) {
                        if (referrerIsValidAddress) {
                          dispatch({
                            type: "SHOW_INFO_POPUP",
                            payload: {
                              infoObject: {
                                title: "Referral Error",
                                level: "error",
                                body: "The address contained in this referral link has not participated in the Presale before and is ineligible for referrals.",
                                showHelpLink: true,
                              },
                            },
                          });
                        } else {
                          setFieldError(
                            "referrer",
                            "This address has not participated in the Presale before and is ineligible for referrals."
                          );
                        }

                        return;
                      }

                      submitForm();
                    }}
                    type="submit"
                    className={
                      "  " +
                      cn([
                        {
                          " opacity-50 cursor-not-allowed ":
                            !isValid || isSubmitting || validating,
                          "cursor-pointer hover:bg-[#FF4820]/80": isValid,
                        },
                      ])
                    }
                    value={validating ? "VALIDATING..." : "BUY TOKEN"}
                    disabled={!isValid || isSubmitting}
                  />
                )}
              </Form>
            );
          }}
        </Formik>

        {auth && (
          <div className="referral">
            REFERRAL LINK:
            <br />
            <button className="tooltip " onClick={copyElementText}>
              <span className="text-sm block text-center italic text-ellipsis overflow-hidden">
                {" "}
                <span className="text-xs text-sky-500">Click to copy - </span>
                {`${config.siteUrl}/r/${shortAddr(state.w3.address)}`}
              </span>
              <span className="tooltiptext">Copy Referral link</span>
            </button>
          </div>
        )}

        {!auth && <br />}

        <p className="">
          Buy now and use your referral link to invite buyers and Earn 10% of
          whatever they buy
        </p>
      </div>

      {showLinkCopied ? (
        <div id="referralCopiedAlert" className="slide-top">
          <p>Copied to Clipboard</p>
        </div>
      ) : (
        <div id="referralCopiedAlert" className="slide-bottom">
          <p>Copied to Clipboard</p>
        </div>
      )}
    </div>
  );
};

export default BuyTokensForm;
