import React from "react";
import { useEffect, useReducer, createContext } from "react";
import uiReducer from "./reducers/ui";
import walletReducer from "./reducers/wallet";
import dataReducer from "./reducers/data.js";
import userReducer from "./reducers/user";
import XLoader from "../components/web3/XLoader";
import isAuth from "../lib/isAuth";
import {
  connectViaMetamask,
  metamaskHandleAccountsChanged,
  metamaskHandleChainChanged,
  metamaskHandleDisconnect,
} from "../lib/wallet";
import ConnectWalletPopup from "../components/web3/ConnnectWalletModal";
import SignMessagePopup from "../components/web3/SignMessagePopup";
import InfoPopup from "../components/web3/InfoPopup";
import ConfirmPopup from "../components/web3/ConfirmationPopup";
import NetworkLoader from "../components/web3/NetworkLoader";
import { assertChainIsSmartchainOrAdd } from "../lib/wallet";
import { connectViaWalletconnect } from "../lib/wallet";
import { useLocation } from "react-router-dom";

export const initializer = {
  ui: {
    mqtt: null,
    showConnectWalletPopup: false,
    showSignMessagePopup: false,
    showUserPanelPopup: false,
    xLoader: false,
    netLoader: false,
    mobileNav: false,
    infoPopup: {
      show: false,
      infoObject: null,
    },
    confirmationPopup: {
      show: false,
      infoObject: null,
    },
  },

  w3: null,
  data: {
    bnb: null,
  },
};

const StoreContext = createContext(initializer);

function rootReducer(state, action) {
  return {
    ui: uiReducer(state.ui, action),
    w3: walletReducer(state.w3, action),
    user: userReducer(state.user, action),
    data: dataReducer(state.data, action),
  };
}

export function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(rootReducer, initializer);

  const { walletAuth, auth } = isAuth(state, dispatch, false, false);

  const href = useLocation();

  function broadcast(msg) {
    // if (state.ui.mqtt) {
    //   state.ui.mqtt.publish('safeprotocol.finance/buys', msg, { qos: 1 })
    // }
  }

  useEffect(() => {
    const excepts = ["/", "/sfp-finance-admin", ""];

    if (excepts.includes(href.pathname)) return;

    async function exec() {
      if (window.screen.availWidth >= 992) {
        await connectViaMetamask(dispatch);
      } else {
        await connectViaWalletconnect(dispatch);
      }
    }

    let id;

    //  id = setTimeout(exec, 750);

    return () => {
      clearTimeout(id);
    };
  }, [href.pathname]);

  useEffect(() => {
    return () => {
      if (!walletAuth || state.w3.connectionChannel !== "METAMASK") return;

      const p = state.w3.provider;
      // console.log("Remove EVenets Launched!");
      p.removeListener(
        "accountsChanged",
        metamaskHandleAccountsChanged(dispatch)
      );
      p.removeListener("chainChanged", metamaskHandleChainChanged(dispatch));
    };
  }, []);

  return (
    <StoreContext.Provider value={{ state, dispatch, auth, broadcast }}>
      {state.ui.xLoader && <XLoader />}
      {state.ui.netLoader && <NetworkLoader />}
      {state.ui.showConnectWalletPopup && <ConnectWalletPopup />}
      {state.ui.showSignMessagePopup && <SignMessagePopup />}
      {state.ui.infoPopup.show && (
        <InfoPopup
          dispatch={dispatch}
          payload={state.ui.infoPopup.infoObject}
        />
      )}

      {state.ui.confirmationPopup.show && (
        <ConfirmPopup
          dispatch={dispatch}
          payload={state.ui.confirmationPopup.infoObject}
        />
      )}

      {children}
    </StoreContext.Provider>
  );
}

export default StoreContext;
