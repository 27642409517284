import React from "react";
import { bnb, coingecko, pancakeswap, coinmarketcap } from "./imports";
import "./brand.css";

const Brand = () => {
  return (
    <div className="gpt3__brand section__padding">
      <div>
        <img src={bnb} alt="bnb" />
      </div>
      <div>
        <img src={coingecko} alt="coingecko" className="make-bigger" />
      </div>
      <div>
        <img src={pancakeswap} alt="pancakeswap" />
      </div>
      <div>
        <img src={coinmarketcap} alt="coinmarketcap" />
      </div>
    </div>
  );
};

export default Brand;
