export default function isAuth(
    state,
    dispatch,
    allowPopup = true,
    log = false
  ) {
    const walletAuth = Boolean(
      state.w3 &&
        state.w3.web3 &&
        state.w3.networkId &&
        state.w3.connectionChannel
    );
    // const userAuth = Boolean(state.user && state.user.signature)
    const userAuth = true;
  
    if (!walletAuth && allowPopup) {
      dispatch({ type: "SHOW_CONNECT_WALLET_POPUP" });
    }
    
    const r = {
      walletAuth,
      userAuth,
      auth: walletAuth && userAuth,
    };
  
    if (log) {
      console.log("\n Authentication: ", r);
    }
  
    //   console.log(" W3 : ", state.w3);
  //   console.log("User : ", state.user);
  
    return r;
  }
  