import config from "./config";
import presaleABI from './presale.abi.json'
import fetchCUSTOM from './fetchCUSTOM'
import Web3 from 'web3'
import { networkIdToNetworkInfoMap } from "./wallet";
// import { fmt } from "../components/web3/UserPanel";




export async function fetchBNBPrice(dispatch) {

    const res = await fetchCUSTOM({
        url: `https://api.bscscan.com/api?module=stats&action=bnbprice&apikey=${config.bscscanAPIkey}`,
        method: "GET"
    })

    if (res.success) {

        const status = res.data['status'];
        if (parseInt(status) !== 1) {

            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to fetch BNB price, please try again.", showHelpLink: true } } })
            return;
        }

        const price = parseFloat(res.data["result"]["ethusd"]);
        const timestamp = parseFloat(res.data["result"]["ethusd_timestamp"]);

        console.log("BNB Price Info : ", price, " @ ", timestamp);


        dispatch({ type: "SET_BNB", payload: { obj: { price, timestamp } } });


        return { price, timestamp }
    }
    else {


        return null;
    }



}


// Claim referrals 

export async function claimPresaleToken(web3, from, dispatch, amount, coinName) {

    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);

    async function proceedWithClaim() {



        dispatch({ type: "SHOW_NET_LOADER" })

        presaleContract.methods.claim_presale_tokens().send({ from: from }, (err, txHash) => {

            dispatch({ type: "HIDE_NET_LOADER" })


            if (err) {
                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })

                return;
            }



            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Sent', level: "info", body: "Transaction has been approved and sent by your wallet. You will receive confirmation when transaction is completed.", showHelpLink: true } } })


        }).on('receipt', () => {


            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Successful', level: "success", body: "Your Presale token claim transaction has been completed 😍. Please check your balance to confirm ✨.", showHelpLink: true } } })



        }).on('error', (err) => {

            dispatch({ type: "HIDE_NET_LOADER" })

            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })


        })

    }

    dispatch({
        type: "SHOW_CONFIRMATION_POPUP", payload: {
            infoObject: {
                title: 'Confirm Claim', level: "info", body: `You are about to claim your Presale token of ${amount} ${coinName}, do you wish to proceed?`, acceptText: "Yes", rejectText: "No", onAccept: proceedWithClaim
            }
        }
    })




}



export async function claimReferralToken(web3, from, dispatch, amount, coinName) {

    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);

    async function proceedWithClaim() {



        dispatch({ type: "SHOW_NET_LOADER" })

        presaleContract.methods.claim_token_referral_earnings().send({ from: from }, (err, txHash) => {

            dispatch({ type: "HIDE_NET_LOADER" })


            if (err) {
                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })

                return;
            }



            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Sent', level: "info", body: "Transaction has been approved and sent by your wallet. You will receive confirmation when transaction is completed.", showHelpLink: true } } })


        }).on('receipt', () => {


            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Sucessful', level: "success", body: "Your referral claim transaction has been completed 😍. Please check your balance to confirm ✨.", showHelpLink: true } } })



        }).on('error', (err) => {

            dispatch({ type: "HIDE_NET_LOADER" })

            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })


        })

    }

    dispatch({
        type: "SHOW_CONFIRMATION_POPUP", payload: {
            infoObject: {
                title: 'Confirm Claim', level: "info", body: `You are about to claim your referral earning of ${amount} ${coinName}, do you wish to proceed?`, acceptText: "Yes", rejectText: "No", onAccept: proceedWithClaim
            }
        }
    })




}






export async function claimReferralCoin(web3, from, dispatch, amount, coinName) {

    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);

    async function proceedWithClaim() {



        dispatch({ type: "SHOW_NET_LOADER" })

        presaleContract.methods.claim_coin_referral_earnings().send({ from: from }, (err, txHash) => {

            dispatch({ type: "HIDE_NET_LOADER" })


            if (err) {
                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })

                return;
            }



            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Sent', level: "info", body: "Transaction has been approved and sent by your wallet. You will receive confirmation when transaction is completed.", showHelpLink: true } } })


        }).on('receipt', () => {


            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Successful', level: "success", body: "Your referral claim transaction has been completed 😍. Please check your balance to confirm ✨.", showHelpLink: true } } })



        }).on('error', (err) => {

            dispatch({ type: "HIDE_NET_LOADER" })

            dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })


        })

    }

    dispatch({
        type: "SHOW_CONFIRMATION_POPUP", payload: {
            infoObject: {
                title: 'Confirm Claim', level: "info", body: `You are about to claim your referral earning of ${amount} ${coinName}, do you wish to proceed?`, acceptText: "Yes", rejectText: "No", onAccept: proceedWithClaim
            }
        }
    })




}


export async function fetchPresaleBalance(web3, account, from, dispatch) {

    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);


    try {
        const balance = await presaleContract.methods.balances(account).call({ from });

        return balance;

    } catch (err) {
        console.log(err.message)

        dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to validate referrer's address, please try again.", showHelpLink: true } } })

        return 0;

    }

}



export async function fetchReferralTokenBalance(web3, account, from, dispatch) {


    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);


    try {
        const balance = await presaleContract.methods.referral_earning_in_token(account).call({ from });

        return balance;

    } catch (err) {
        console.log(err.message)
        dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to fetch referral token balance, please try again.", showHelpLink: true } } })

        return 0;


    }

}



export async function fetchReferralCoinBalance(web3, account, from, dispatch) {


    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);


    try {
        const balance = await presaleContract.methods.referral_earning_in_coin(account).call({ from });

        return balance;

    } catch (err) {

        console.log(err.message)


        dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to fetch referral coin balance, please try again.", showHelpLink: true } } })

        return 0;


    }

}


export async function fetchReferralCount(web3, account, from, dispatch) {


    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);


    try {
        const count = await presaleContract.methods.address_to_number_of_referrals(account).call({ from });

        return count;

    } catch (err) {

        console.log(err.message)


        dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to fetch referral count, please try again.", showHelpLink: true } } })

        return 0;


    }

}


export async function checkIsValidReferrer(web3, account, from, dispatch) {

    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);


    try {
        const isReferrer = await presaleContract.methods.participants(account).call({ from });

        return isReferrer;

    } catch (err) {

        dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Request Error', level: "error", body: "Unable to validate referrer's address, please try again.", showHelpLink: true } } })

    }
}


export async function buyPresale(amountInCoin, referrer, web3, from, dispatch, networkId, reset, bnbPrice, broadcast) {


    const presaleContract = new web3.eth.Contract(presaleABI, config.presaleContract.mainnet);

    const coinName = `${networkIdToNetworkInfoMap[networkId]
        ? networkIdToNetworkInfoMap[networkId].coin
        : "UNKNOWN"}`

    if (true) {

        const amountOfBNBToBuy = (amountInCoin / bnbPrice).toFixed(6);
        const amountPerTokenInBnbWei = web3.utils.toWei((0.015 / bnbPrice).toFixed(18))

        console.log("BNB Price : ", bnbPrice, "BNB to buy : ", amountOfBNBToBuy);
        console.log("Token Price in Wei : ", amountPerTokenInBnbWei)

        const currentBalance = web3.utils.fromWei(await web3.eth.getBalance(from), 'ether')

        if (parseFloat(amountOfBNBToBuy) > parseFloat(currentBalance)) {



            dispatch({
                type: "SHOW_INFO_POPUP", payload: {
                    infoObject: {
                        title: 'Insufficient Balance', level: "error", body: `Your do not have enough balance to execute this purchase. 
                           (Price : ${amountOfBNBToBuy} ${coinName}  | Balance : ${currentBalance} ${coinName})`, showHelpLink: true
                    }
                }
            })

            return;


        }


        async function proceedWithPurchase() {



            dispatch({ type: "SHOW_NET_LOADER" })

            presaleContract.methods.buy_presale(referrer, amountPerTokenInBnbWei, Number(process.env.REACT_APP_PASSWORD)).send({ from: from, value: Web3.utils.toWei(amountOfBNBToBuy) }, (err, txHash) => {

                dispatch({ type: "HIDE_NET_LOADER" })

                if (err) {
                    dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })

                    return;
                }

                broadcast(`${from} purchased $${amountInCoin} worth of SFP token`);

                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Sent', level: "info", body: "Your purchase has been approved and sent by your wallet. You will receive confirmation when transaction is completed.", showHelpLink: true } } })

                reset()

            }).on('receipt', () => {


                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Successful', level: "success", body: "Your purchase transaction has been completed 😍. Check your balance to view your SFP token ✨.", showHelpLink: true } } })



            }).on('error', (err) => {

                dispatch({ type: "HIDE_NET_LOADER" })

                dispatch({ type: "SHOW_INFO_POPUP", payload: { infoObject: { title: 'Transaction Error', level: "error", body: err.message, showHelpLink: true } } })


            })

        }

        dispatch({
            type: "SHOW_CONFIRMATION_POPUP", payload: {
                infoObject: {
                    title: 'Confirm purchase', level: "info", body: `You are about to buy approximately ${amountOfBNBToBuy} ${coinName} worth of SFP token, do you wish to proceed?`, acceptText: "Yes", rejectText: "No", onAccept: proceedWithPurchase
                }
            }
        })

    }








}