import React from "react";
import { FaTelegram } from "react-icons/fa";
import "./privateSale.css";
import privateSaleImage from "../../assets/possibility.png";

const Possibility = () => {
  return (
    <div className="id__positioning" id="private-sale">
      <div className="gpt3__private_sale section__padding">
        <div className="gpt3__private_sale-image">
          <img src={privateSaleImage} alt="Presale" />
        </div>
        <div className="gpt3__private_sale-content">
          <h4> We launch soon!</h4>
          <h1 className="gradient__text">
            The possibilities are beyond your imagination
          </h1>
          {/* <p>
            Join our Presale Round 1 and buy SFP at the cheapest possible price
          </p> */}
          <a href="https://t.me/safeprotocol">
            <button type="button" className="gp-icon-button">
              <span style={{ paddingTop: "5px" }}>
                <FaTelegram />
              </span>
              Join us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Possibility;
