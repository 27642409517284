import React from "react";
import { AiOutlineTwitter, AiOutlineGithub } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import { FaTelegramPlane, FaFacebook } from "react-icons/fa";
import "./community.css";

const Footer = () => {
  return (
    <div className="id__positioning" id="connect">
      <div className="gpt3__connect section__padding">
        <div className="gpt3__connect-content">
          <h1 className="gradient__text">
            Join us now, be a part of our community
          </h1>
          {/* <p>Get in touch with us and stay updated</p> */}
          <div>
            <a href="https://twitter.com/safeprotocl_hq">
              <AiOutlineTwitter />
              <span>Twitter</span>
            </a>
            <a href="https://t.me/safeprotocol">
              <FaTelegramPlane />
              <span>Telegram</span>
            </a>

            <a href="https://www.facebook.com/safeprotocol">
              <FaFacebook />
              <span>Facebook</span>
            </a>
            {/* <a href="/">
              <BsDiscord />
              <span>Discord</span>
            </a> */}
            <a href="/">
              <AiOutlineGithub />
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
