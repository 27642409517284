import MQTT from "async-mqtt";
import { useEffect, useState, useContext, useRef } from "react";
import StoreContext from "../../store/AppStore";

const brokerUrl = "wss://test.mosquitto.org:8081";

export default function LiveMonitor() {
  const { dispatch, state } = useContext(StoreContext);
  const [msgs, setMsgs] = useState([]);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState("");
  const [disable, setDisable] = useState(false);

  const msgsRef = useRef();

  msgsRef.current = msgs;

  // useEffect(() => {
  //   let tmrId;

  //   if (msgs.length > 0 && !disable ) {
  //     setDisable(true);
  //     setCurrent(msgs[0]);
  //     setShow(true);
  //     if (msgs.length === 1) {
  //       setMsgs([]);
  //     } else {
  //       setMsgs(msgs.slice(1));
  //     }

  //     tmrId = setTimeout(() => {
  //       setShow(false);
  //       setDisable(false);

  //       // console.log("Seq: ", msgs)

  //       clearTimeout(tmrId);
  //     }, 5000);


  //   }


  // }, [disable, msgs]);

  // useEffect(() => {
  //   // console.log("working mqtt")
  //   if (state.ui.mqtt) {
  //     // console.log(state.ui.mqtt)
  //     console.log("MQTT active already.");
  //     return;
  //   }

  //   console.log("Starting MQTT...");

  //   const c = MQTT.connect(brokerUrl);

  //   dispatch({ type: "SET_MQTT", payload: { mqtt: c } });

  //   c.on("connect", () => {
  //     c.subscribe("safeprotocol.finance/buys", { qos: 1 }, (err) => {
  //       if (err) {
  //         console.log("MQTT error : ", err.message);
  //       } else {
  //         console.log("MQTT connected");
  //       }
  //     });
  //   });

  //   c.on("message", (topic, message) => {
  //     const decodedMsg = message.toString();

  //   //   console.log("NEW MQTT MSG : ", decodedMsg);
  //   //   console.log(msgsRef.current)
  //     setMsgs(msgsRef.current.concat(decodedMsg));
  //   });

  //   return async () => {
  //     await c.end();
  //   };
  // }, []);

  if (!show) return null;

  return (
    <div style = {{fontFamily:"Manrope"}} className="fixed left-0 right-0 mt-20 md:mt-24 text-center z-[200] bg-black/50 text-white py-2 px-4 text-sm md:text-base ">
      {current}
    </div>
  );
}
