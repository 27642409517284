import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider as AppStore } from './store/AppStore'

import App from "./App";
import "./index.css";



ReactDOM.render(
  <Router>
    <AppStore>
      <App />
    </AppStore>
  </Router>
  ,
  document.getElementById("root")
);
