import { useState, useEffect } from "react";

export default function XLoader({ progressive = false, continuous = true }) {
  const [displacement, setDisplacement] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!continuous) return;

    const timerId = setTimeout(() => {
      if (displacement >= 90) {
        setDisplacement(0);
      } else {
        setDisplacement(displacement + 2.5);
      }
    }, 10);

    return () => {
      clearTimeout(timerId);
    };
  });

  useEffect(() => {
    if (!progressive) return;

    const timerId = setTimeout(() => {
      if (progress < 50) {
        setProgress(progress + 1);
      }

      if (progress >= 50 && progress < 90) {
        setProgress(progress + 0.75);
      }
    }, 10);

    return () => {
      clearTimeout(timerId);
    };
  });

  return (
    <>
      <div className="z-40  fixed inset-x-0 top-0 bg-white  h-[3.5px] md:h-[2px] shadow">
        {continuous && (
          <div
            style={{
              left: `${displacement}%`,
            }}
            className=" absolute w-[15%] bg-gradient-to-tr from-gray-300 via-black to-gray-300 h-full"
          ></div>
        )}

        {progressive && (
          <div
            style={{
              width: `${progress}%`,
            }}
            className=" absolute  bg-gradient-to-tr from-gray-300 via-black to-gray-300 h-full"
          ></div>
        )}
      </div>
    </>
  );
}
