import React, { useState, useContext } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import logo from "../../assets/logo.svg";
import StoreContext from "../../store/AppStore";
import UserPanel from "../web3/UserPanel";
import cn from "classnames";
import { Link } from "react-router-dom";

const Menu = () => (
  <>
    <p>
      <a href="/#home">Home</a>
    </p>
    <p>
      <a href="/#about">About Us</a>
    </p>
    {/* <p>
      <Link to="/private-sale">Presale</Link>
    </p> */}
    <p>
      <a href="/#connect">Community</a>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { dispatch, state, auth: authenticated } = useContext(StoreContext);
  const showUserPanel = state.ui.showUserPanelPopup;

  function close() {
    setToggleMenu(false);
    dispatch({ type: "HIDE_USER_PANEL_POPUP" });
  }

  function open() {
    setToggleMenu(false);
    dispatch({ type: "SHOW_USER_PANEL_POPUP" });
  }

  function handleConnect() {
    setToggleMenu(false);
    dispatch({ type: "SHOW_CONNECT_WALLET_POPUP" });
  }

  function handleDisconnect() {
    setToggleMenu(false);
    dispatch({ type: "CLEAR_W3" });
    dispatch({ type: "CLEAR_USER" });
  }

  return (
    <div className="">
      {showUserPanel && authenticated && <UserPanel close={close} />}

      <div className="gpt3__navbar ">
        <div className="gpt3__navbar-links">
          <div className="gpt3__navbar-links_logo">
            <a className="md:hidden" href="/#home">
              <img width={136} src={logo} alt="logo" />
            </a>

            {/* {!authenticated && (
              <a className="md:hidden" href="/#home">
                <img src="/Safe-Protocol-Logo.png" alt="logo" />
              </a>
            )} */}

            <a className="hidden md:block" href="/#home">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="gpt3__navbar-links_container">
            <Menu />
          </div>
        </div>

        <div className="gpt3__navbar-sign">
          {/* <a href="/safeprotocol.pdf">White Paper</a> */}

          {authenticated && (
            <div className="hidden lg:flex  mx-6 flex-row space-x-3 items-center justify-evenly ">
              <span
                onClick={showUserPanel ? close : open}
                className={
                  "  bi-wallet2 text-xl text-white box-border md:cursor-pointer   mr-2 md:mr-3  rounded-full ring-1 ring-white/20  px-3 py-2 ring-offset-white bg-[#021933] " +
                  cn([
                    {
                      " ring-offset-[#030E1D]/90 ": showUserPanel,
                      " ring-offset-white ": !showUserPanel,
                    },
                  ])
                }
              ></span>
            </div>
          )}

          {!authenticated && (
            <span onClick={handleConnect} className="bg-red ">
              Connect
            </span>
          )}

          {authenticated && (
            <span onClick={handleDisconnect} className="bg-red ">
              Disconnect
            </span>
          )}
        </div>

        {/* User Panel */}

        <div className="  backdrop lg:hidden flex-row space-x-4 items-center justify-evenly ">
          {!authenticated && (
            <button
              onClick={handleConnect}
              className="bg-red py-1 text-base px-4 rounded-xl outline-none text-white bg-[#FF4820]"
            >
              Connect
            </button>
          )}

          {authenticated && (
            <span
              onClick={showUserPanel ? close : open}
              className={
                "  bi-wallet2 text-xl text-white box-border md:cursor-pointer   mr-2 md:mr-3  rounded-full ring-1 ring-white/20  px-3 py-2 ring-offset-white bg-[#021933] " +
                cn([
                  {
                    " ring-offset-[#030E1D]/90 ": showUserPanel,
                    " ring-offset-white ": !showUserPanel,
                  },
                ])
              }
            ></span>
          )}
        </div>

        <div className="gpt3__navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#fff"
              size={32}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#fff"
              size={32}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {(toggleMenu && (
            <div className="gpt3__navbar-menu_container scale-up-center">
              <div className="gpt3__navbar-menu_container-links">
                <Menu />
                <div className="gpt3__navbar-menu_container-links-sign">
                  {/* <Link to="/whitepaper">White Paper</Link> */}
                  {!authenticated && (
                    <span onClick={handleConnect} className="bg-red">
                      Connect
                    </span>
                  )}
                  {authenticated && (
                    <span onClick={handleDisconnect} className="bg-red">
                      Disconnect
                    </span>
                  )}
                </div>
              </div>
            </div>
          )) || (
            <div className="gpt3__navbar-menu_container scale-down-center">
              <div className="gpt3__navbar-menu_container-links">
                <Menu />
                <div className="gpt3__navbar-menu_container-links-sign">
                  {/* <a href="/safeprotocol.pdf">White Paper</a> */}
                  {/* {!authenticated && (
                    <span onClick={handleConnect} className="bg-red">
                      Connect
                    </span>
                  )}
                  {authenticated && (
                    <span onClick={handleDisconnect} className="bg-red">
                      Disconnect
                    </span>
                  )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
