import React from "react";
import { useContext, useState } from "react";
import StoreContext from "../../store/AppStore";
import { signMessage } from "../../lib/wallet";
import cn from "classnames";
import { useEffect } from "react";

function SignMessagePopup(props) {
  const { state, dispatch } = useContext(StoreContext);
  const [exec, setExec] = useState(false);

  function close() {
    dispatch({ type: "HIDE_SIGN_MESSAGE_POPUP" });
    dispatch({ type: "HIDE_MOBILE_NAV" });
    dispatch({ type: "HIDE_X_LOADER" });
  }

  function disconnect() {
    dispatch({ type: "CLEAR_W3" });
    dispatch({ type: "CLEAR_USER" });
    dispatch({ type: "HIDE_MOBILE_NAV" });
    dispatch({ type: "HIDE_X_LOADER" });

    close();
  }

  async function conductSignIn() {
    if (exec) return;

    setExec(true);

    dispatch({ type: "SHOW_X_LOADER" });

    const message =
      process.env.REACT_APP_ACCOUNT_VERIFICATION_MESSAGE || "message";

    const signature = await signMessage(state, message);

    if (!signature) {
      setExec(false);

      close();

      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Signature Error",
            level: "error",
            body: "Oops! Something went wrong while trying to get your signature, please try again.",
            showHelpLink: true,
          },
        },
      });

      return;
    }

    const signer = await state.w3.web3.eth.personal.ecRecover(
      process.env.REACT_APP_ACCOUNT_VERIFICATION_MESSAGE || "message",
      signature
    );

    if (signer.toLowerCase() !== state.w3.address.toLowerCase()) {
      console.log(signer, "\n", state.w3.address);

      close();

      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Signature Failed",
            level: "error",
            body: "Oops! We couldn't verify that you own this wallet.",
            showHelpLink: true,
          },
        },
      });

      return;
    }

    // console.log(signer, state.w3.address)

    dispatch({ type: "SET_USER", payload: { user: { signature } } });

    dispatch({ type: "HIDE_X_LOADER" });

    setExec(false);
    close();
  }


  return  null;

  // return (
  //   <>
  //     <div className="fixed  md:block top-0 left-0 z-30 right-0 bottom-0 bg-black/90  ">
  //       <i
  //         onClick={close}
  //         className="bi-x bg-white cursor-pointer text-3xl  relative top-[5%] md:top-[2%] left-[80%] md:left-[95%] text-black rounded-full shadow-lg shadow-white/30 border border-black/5 px-[6px] py-[1px]"
  //       ></i>
  //     </div>

  //     <div className="fixed w-[80%] left-0 right-0 px-4 md:px-8 max-w-sm  mx-auto top-24 md:top-32 z-40 rounded-3xl shadow  min-h-[400px] border-2 border-[#011D3C] bg-white ">
  //       <p
  //         style={{
  //           fontFamily: "Manrope",
  //         }}
  //         className="font-bold text-xl  md:text-2xl py-6 text-center "
  //       >
  //         {" "}
  //         Sign the message in your wallet to continue
  //       </p>

  //       <p
  //         style={{
  //           fontFamily: "Manrope",
  //         }}
  //         className="pb-4 text-base font-bold  text-[#011D3C] text-center"
  //       >
  //         We use this signature to verify that you're the owner of this wallet.
  //       </p>

  //       <div className="flex flex-col justify-center items-center mt-4 w-[100%] space-y-6">
  //         <div
  //           onClick={conductSignIn}
  //           className={
  //             "flex flex-row cursor-pointer bg-[#011D3C] hover:bg-[#011D3C]/90  justify-around items-center font-bold  text-white w-[90%] mx-auto px-4 py-3 rounded-xl" +
  //             cn([
  //               {
  //                 " blur-sm  cursor-not-allowed": exec,
  //               },
  //             ])
  //           }
  //         >
  //           {" "}
  //           <span> Sign message in wallet </span>
  //           {exec ? (
  //             <i className="bi-arrow-clockwise animate-spin text-2xl text-white"></i>
  //           ) : (
  //             <i className="bi-lock text-2xl text-white"></i>
  //           )}
  //         </div>

  //         <div
  //           onClick={disconnect}
  //           className="flex cursor-pointer  flex-row text-lg justify-center items-center font-bold  text-black w-[90%] mx-auto px-4 py-3 rounded-xl"
  //         >
  //           {" "}
  //           <span
  //             style={{
  //               fontFamily: "raleway",
  //             }}
  //           >
  //             {" "}
  //             Disconnect{" "}
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}

SignMessagePopup.propTypes = {};

export default SignMessagePopup;
