export default function userReducer(state, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, ...action.payload.user };
      break;

    case "CLEAR_USER": return null;
      break;
    default:
      return state;
  }
}
