import { useContext, useEffect, useState } from "react";
import StoreContext from "../../store/AppStore";
import OutsideClickHandler from "react-outside-click-handler";
import isAuthenticated from "../../lib/isAuth";
import { networkIdToNetworkInfoMap } from "../../lib/wallet";
import {
  fetchPresaleBalance,
  fetchReferralTokenBalance,
  fetchReferralCoinBalance,
  claimReferralCoin,
  fetchReferralCount,
  claimReferralToken,
  claimPresaleToken,
} from "../../lib/presale";
import config from "../../lib/config";
import Web3 from "web3";

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
    return successful;
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
  return false;
}

export function shortAddr(addr) {
  const length = String(addr).length;
  const start = String(addr).slice(0, 4);

  const end = String(addr).slice(length - 5, length - 1);

  return `${start}...${end}`;
}

export function fmt(value) {
  value = value.toString ? value.toString() : value;
  return parseFloat(Web3.utils.fromWei(value, "ether")).toFixed(5);
}

function UserPanel({ close }) {
  const { state, dispatch } = useContext(StoreContext);
  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const [f1, setf1] = useState(false);
  const [f2, setf2] = useState(false);
  const [f3, setf3] = useState(false);
  const [f4, setf4] = useState(false);
  const [f5, setf5] = useState(false);

  const [c1, setc1] = useState(false);
  const [c2, setc2] = useState(false);
  const [c3, setc3] = useState(false);

  const [d1, setd1] = useState(0);
  const [d2, setd2] = useState(0);
  const [d3, setd3] = useState(0);
  const [d4, setd4] = useState(0);
  const [d5, setd5] = useState(0);

  const { auth } = isAuthenticated(state, dispatch, false, false);

  var copyElementText = () => {
    navigator.clipboard
      .writeText(`${config.siteUrl}/r/${state.w3.address}`)
      .then(
        () => {
          setShowLinkCopied(true);
          setTimeout(() => {
            setShowLinkCopied(false);
          }, 2000);
        },

        () => {
          fallbackCopyTextToClipboard(
            `${config.siteUrl}/r/${state.w3.address}`
          );

          setShowLinkCopied(true);
          setTimeout(() => {
            setShowLinkCopied(false);
          }, 2000);
        }
      );
  };

  const coinName = networkIdToNetworkInfoMap[state.w3.networkId]
    ? networkIdToNetworkInfoMap[state.w3.networkId].coin
    : "UNKNOWN";

  async function claimPresaleBalance() {
    if (c1) return;

    setc1(true);
    const balance = parseFloat(d1);

    close();
    if (!balance > 0) {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Insufficient Balance",
            level: "error",
            body: "You do not have enough balance to claim. Refer more people to earn more.",
          },
        },
      });
    } else {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "",
            level: "info",
            body: "Tokens will be available for claiming after token sales.",
            showHelpLink: true,
          },
        },
      });
      // claimPresaleToken(state.w3.web3, state.w3.address, dispatch, d1, "SFP");
    }

    setc1(false);
  }

  async function claimTokenReferralBalance() {
    if (c3) return;

    setc3(true);
    const balance = parseFloat(d3);
    close();

    if (!balance > 0) {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Insufficient Balance",
            level: "error",
            body: "You do not have enough balance to claim. Refer more people to earn more.",
          },
        },
      });
    } else {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "",
            level: "info",
            body: "Tokens will be eligible for claiming after token sales.",
            showHelpLink: true,
          },
        },
      });

      // claimReferralToken(state.w3.web3, state.w3.address, dispatch, d3, "SFP");
    }

    setc3(false);
  }

  async function claimCoinReferralBalance() {
    if (c2) return;

    setc2(true);
    const balance = parseFloat(d2);

    close();
    if (!balance > 0) {
      dispatch({
        type: "SHOW_INFO_POPUP",
        payload: {
          infoObject: {
            title: "Insufficient Balance",
            level: "error",
            body: "You do not have enough balance to claim. Refer more people to earn more.",
          },
        },
      });
    } else {
      claimReferralCoin(
        state.w3.web3,
        state.w3.address,
        dispatch,
        d2,
        coinName
      );
    }

    setc2(false);
  }

  async function synData() {
    setf1(true);
    setf2(true);
    setf3(true);
    setf4(true);
    setf5(true);

    setd1(
      await fetchPresaleBalance(
        state.w3.web3,
        state.w3.address,
        state.w3.address,
        dispatch
      )
    );
    setf1(false);

    setd2(
      fmt(
        await fetchReferralCoinBalance(
          state.w3.web3,
          state.w3.address,
          state.w3.address,
          dispatch
        )
      )
    );
    setf2(false);

    setd3(
      await fetchReferralTokenBalance(
        state.w3.web3,
        state.w3.address,
        state.w3.address,
        dispatch
      )
    );
    setf3(false);

    setd4(fmt(await state.w3.web3.eth.getBalance(state.w3.address)));
    setf4(false);

    setd5(
      await fetchReferralCount(
        state.w3.web3,
        state.w3.address,
        state.w3.address,
        dispatch
      )
    );
    setf5(false);
  }

  useEffect(() => {
    if (!auth) return;

    synData();
  }, [auth]);

  useEffect(() => {
    if (auth || !state.ui.showSignMessagePopup) return;

    dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
    dispatch({ type: "SHOW_SIGN_MESSAGE_POPUP" });
  }, []);

  function disconnect() {
    dispatch({ type: "CLEAR_W3" });
    dispatch({ type: "CLEAR_USER" });
    close();
  }

  if (!auth) return null;

  return (
    <>
      <div className="fixed overflow-y-auto top-0 left-0 z-10 right-0 bottom-0 bg-black/90  ">
        <i
          onClick={close}
          className="bi-x bg-white cursor-pointer text-3xl  relative top-[2%] md:top-[2%] left-[80%] md:left-[95%] text-black rounded-full shadow-lg shadow-white/30 border border-black/5 px-[6px] py-[1px]"
        ></i>
      </div>

      <div
        style={{ fontFamily: "Manrope" }}
        className="fixed w-[85%] transform scale-90 px-4 max-w-sm flex flex-col justify-center mx-auto left-0 right-0 top-14 overflow-y-auto md:top-20 z-20 rounded-xl shadow  min-h-[250px]  bg-white py-2"
      >
        <OutsideClickHandler onOutsideClick={close}>
          <div className="flex flex-row  py-2 max-w-sm rounded-3xl mt-4 justify-between space-x-4 items-center">
            <div className="flex flex-row justify-start items-center space-x-4">
              {/* <img
                  className="rounded-full"
                  height={48}
                  width={48}
                  objectFit="contain"
                  src="/avatar.jpg"
                  layout="fixed"
                /> */}

              <span className="h-12 w-12 rounded-full bg-gray-200"></span>

              <span className="font-bold text-black text-left text-xs md:text-sm">
                <p className="font-bold block text-sky-500 text-left text-xs md:text-sm">
                  {" "}
                  Referral link{" "}
                </p>

                {`${config.siteUrl}/r/${shortAddr(state.w3.address)}`}
                <br />
                <button
                  onClick={copyElementText}
                  className="px-6 hover:bg-black/20 self-center mt-1 text-sky-500 block bg-black/10 shadow py-[2px] rounded-3xl text-xs"
                >
                  {" "}
                  {showLinkCopied ? "Copied" : "Copy"}
                </button>
              </span>
            </div>

            {/* Arrow right  */}

            {/* <a href = '/' className="block">
              <i className="bi-chevron-compact-right text-xl text-black"></i>
            </a> */}
          </div>

          {/* Enclosed table kinda  */}

          <div
            style={{ fontFamily: "Manrope" }}
            className="border mt-2 p-2 border-black/10 rounded-xl flex flex-col justify-between"
          >
            <div
              style={{ fontFamily: "Manrope" }}
              className="border  p-2 border-black/10 rounded-xl flex flex-row justify-between"
            >
              <div
                style={{
                  fontFamily: "Manrope",
                }}
              >
                <b className="font-bold  text-black text-left text-sm md:text-sm">
                  Wallet balance
                </b>

                <div className="flex flex-col  max-w-sm rounded-3xl  justify-start  items-start">
                  <span className="font-bold text-black/90 text-left text-base md:text-lg">
                    {f4 ? (
                      <i className="bi-arrow-clockwise animate-spin text-base text-black"></i>
                    ) : (
                      d4
                    )}{" "}
                    {networkIdToNetworkInfoMap[state.w3.networkId]
                      ? networkIdToNetworkInfoMap[state.w3.networkId].coin
                      : "UNKNOWN"}
                  </span>
                </div>
              </div>

              {/* Wallet balance  */}

              <span
                style={{
                  fontFamily: "monospace",
                }}
                className=" bg-black/10 px-2 self-start py-[2px] inline-block text-black/60 rounded-3xl text-left text-xs"
              >
                {shortAddr(state.w3.address)}
                <span className="h-4 w-4 align-middle ml-2 rounded-full inline-block bg-sky-500"></span>
              </span>
            </div>

            <div
              style={{ fontFamily: "Manrope" }}
              className="border mt-2 p-2 border-black/10 rounded-xl flex flex-row justify-between"
            >
              <div
                style={{
                  fontFamily: "Manrope",
                }}
              >
                <b className="font-bold  text-black text-left text-sm md:text-sm">
                  Referral
                </b>

                <div className="flex flex-col  max-w-sm rounded-3xl  justify-start  items-start">
                  <span className="font-bold text-black/90 text-left text-base md:text-lg">
                    {f2 ? (
                      <i className="bi-arrow-clockwise animate-spin text-base text-black"></i>
                    ) : (
                      d2
                    )}{" "}
                    {networkIdToNetworkInfoMap[state.w3.networkId]
                      ? networkIdToNetworkInfoMap[state.w3.networkId].coin
                      : "UNKNOWN"}
                  </span>
                </div>
              </div>

              <button
                onClick={claimCoinReferralBalance}
                className="px-6 hover:bg-black/20 self-center mt-1 text-sky-500 block bg-black/10 shadow py-1 rounded-3xl text-xs"
              >
                {" "}
                Claim{" "}
              </button>
            </div>
          </div>

          <div
            style={{ fontFamily: "Manrope" }}
            className="border mt-4 p-2 border-black/10 rounded-xl flex flex-col justify-between"
          >
            <div
              style={{ fontFamily: "Manrope" }}
              className="border  p-2 border-black/10 rounded-xl flex flex-row justify-between"
            >
              <div
                style={{
                  fontFamily: "Manrope",
                }}
              >
                <b className="font-bold  text-black text-left text-sm md:text-sm">
                  Presale balance
                </b>

                <div className="flex flex-col  max-w-sm rounded-3xl  justify-start  items-start">
                  <span className="font-bold text-black/90 text-left text-base md:text-lg">
                    {f1 ? (
                      <i className="bi-arrow-clockwise animate-spin text-base text-black"></i>
                    ) : (
                      d1
                    )}{" "}
                    SFP
                  </span>
                </div>
              </div>

              <button
                onClick={claimPresaleBalance}
                className="px-6 hover:bg-black/20 self-center mt-1 text-sky-500 block bg-black/10 shadow py-1 rounded-3xl text-xs"
              >
                {" "}
                Claim{" "}
              </button>

              {/* Wallet balance  */}
            </div>

            <div
              style={{ fontFamily: "Manrope" }}
              className="border mt-2 p-2 border-black/10 rounded-xl flex flex-row justify-between"
            >
              <div
                style={{
                  fontFamily: "Manrope",
                }}
              >
                <b className="font-bold  text-black text-left text-sm md:text-sm">
                  Referral
                </b>

                <div className="flex flex-col  max-w-sm rounded-3xl  justify-start  items-start">
                  <span className="font-bold text-black/90 text-left text-base md:text-lg">
                    {f3 ? (
                      <i className="bi-arrow-clockwise animate-spin text-base text-black"></i>
                    ) : (
                      d3
                    )}{" "}
                    SFP
                  </span>
                </div>
              </div>

              <button
                onClick={claimTokenReferralBalance}
                className="px-6 hover:bg-black/20 self-center mt-1 text-sky-500 block bg-black/10 shadow py-1 rounded-3xl text-xs"
              >
                {" "}
                Claim{" "}
              </button>
            </div>

            {/* Wallet balance  */}
          </div>

          <div
            style={{ fontFamily: "Manrope" }}
            className="border mt-2 p-2 border-black/10 rounded-xl flex flex-row justify-between"
          >
            <span> Referrals </span>
            <span className="font-bold text-black/90 text-left text-base md:text-lg">
              {f5 ? (
                <i className="bi-arrow-clockwise animate-spin text-base text-black"></i>
              ) : (
                d5
              )}
            </span>
          </div>

          {/* Network and Chain  */}

          <div className="border mt-2 p-2 border-black/10 rounded-xl flex flex-row justify-between">
            <div className="flex flex-col  max-w-sm rounded-3xl  justify-start  items-start">
              <span className="font-bold text-sky-500 text-left text-sm md:text-sm">
                {networkIdToNetworkInfoMap[state.w3.networkId]
                  ? networkIdToNetworkInfoMap[state.w3.networkId].name
                  : "Wrong Network"}
              </span>
            </div>

            {/* Chain name  */}
          </div>

          {/* Context Menu   */}

          <div className="pt-4  hidden space-y-4">
            <div
              onClick={disconnect}
              className="flex flex-row cursor-pointer bg-black/10  px-2  py-2 max-w-sm rounded-3xl  justify-between space-x-4 items-center"
            >
              <div className="flex flex-row justify-start items-center space-x-4">
                <i className="bi-power text-2xl text-black"></i>

                <span className="font-bold text-black text-left text-sm md:text-base">
                  Disconnect
                </span>
              </div>

              {/* Power  */}

              <i className="bi-chevron-compact-right text-xl text-black/60"></i>
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
}

UserPanel.propTypes = {};

export default UserPanel;
