import { useState, useEffect } from 'react'
import ems from 'enhanced-ms'

export default function useCounter(timestamp, down = true) {
  const now = Date.now()
  let ts =   (timestamp - now ) 
  const [value, setValue] = useState(ts);

  // console.log(timestamp, now, ts)

  useEffect(() => {

    if (ts < 0)return;

    let timerId = setTimeout(() => {
      if (down) {
        setValue(value - 1000)
      } else {
        setValue(value + 1000)
      }
    }, 1000)

    return () => {
      clearTimeout(timerId)
    }
  }, [value])

  return {
    elapsed : ts < 0,
    timeCount : value, 
    display : ems(value)
  }
}
