import React from "react";
import { useContext, useState } from "react";
import StoreContext from "../../store/AppStore";
import { connectViaMetamask, connectViaWalletconnect } from "../../lib/wallet";
import config from "../../lib/config";
import cn from "classnames";

function ConnectWalletPopup(props) {
  const { dispatch, auth } = useContext(StoreContext);
  const [exec, setExec] = useState(0);

  function close() {
    dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
    dispatch({ type: "HIDE_MOBILE_NAV" });
    dispatch({ type: "HIDE_X_LOADER" });
  }

  async function viaMetamask() {
    if (exec !== 0) return;

    setExec(2);
    await connectViaMetamask(dispatch);
    setExec(0);
  }

  async function viaTrustWallet() {
    if (exec !== 0) return;

    setExec(3);

    window.location.replace(
      `https://link.trustwallet.com/open_url?coin_id=714&url=${config.siteUrl}`
    );

    setExec(0);
  }

  async function viaWalletconnect() {
    if (exec !== 0) return;

    setExec(1);
    await connectViaWalletconnect(dispatch);
    setExec(0);
  }

  return (
    <>
      <div className="fixed  md:block top-0 left-0 z-30 right-0 bottom-0 bg-black/90  ">
        <i
          onClick={close}
          className="bi-x bg-white cursor-pointer text-3xl  relative top-[5%] md:top-[2%] left-[80%] md:left-[95%] text-black rounded-full shadow-lg shadow-white/30 border border-black/5 px-[6px] py-[1px]"
        ></i>
      </div>

      <div className="fixed w-[80%] px-2 max-w-md mx-auto top-24 left-0 right-0 md:top-32 z-40 rounded-3xl shadow  min-h-[400px]  bg-white ">
        <p
          style={{
            fontFamily: "Poppins",
          }}
          className="font-bold text-3xl py-4 text-center "
        >
          {" "}
          Select a wallet{" "}
        </p>

        <p
          style={{
            fontFamily: "Poppins",
          }}
          className="pb-4 text-base  text-black/70 text-center"
        >
          {" "}
          By connecting with your wallet, you agree to our{" "}
          <span className="text-black/80 font-bold">
            Terms of Service{" "}
          </span> and{" "}
          <span className="text-black/80 font-bold"> Privacy Policy </span>.
        </p>

        <div className="w-[90%] mx-auto"> 
          <div
            onClick={viaWalletconnect}
            className={
              "flex flex-row justify-between items-center font-bold bg-[#011D3C] hover:bg-[#011D3C]/90 cursor-pointer text-white w-[100%] mx-auto px-4  rounded-xl " +
              cn([
                {
                  " blur-sm ": exec !== 0 && exec !== 1,
                  " py-3 ": exec === 1,
                },
              ])
            }
          >
            {" "}
            <span> WalletConnect 

            <p className="text-[10px] text-left text-sky-500 m-0 ">
            Connect Trust Wallet{" "}
          </p>
            </span>
            
            {exec === 1 ? (
              <i className="bi-disc self-center animate-spin text-xl text-white">
                {" "}
              </i>
            ) : (
              <img
                src="/wallet/walletconnect.png"
                height={52}
                width={48}
                layout="fixed"
                alt="walletconnect"
              />
            )}
          </div>
         
        </div>

        <div
          className={
            "flex flex-col justify-center items-center w-[100%] space-y-6 "
          }
        >
          <div
            onClick={viaTrustWallet}
            className={
              " flex-row hidden justify-between items-center font-bold bg-[#011D3C] hover:bg-[#011D3C]/90 cursor-pointer text-white w-[90%] mx-auto px-4 py-2 rounded-xl " +
              cn([
                {
                  " blur-sm ": exec !== 0 && exec !== 3,
                  " py-3 ": exec === 3,
                },
              ])
            }
          >
            {" "}
            <span> Trust Wallet </span>
            {exec === 3 ? (
              <i className="bi-disc self-center animate-spin text-xl text-white">
                {" "}
              </i>
            ) : (
              <img
                src="/wallet/TWT.svg"
                height={36}
                width={36}
                layout="fixed"
                alt="walletconnect"
              />
            )}
          </div>

          <div
            onClick={viaMetamask}
            className={
              "flex flex-row justify-between items-center font-bold bg-[#011D3C] hover:bg-[#011D3C]/90 cursor-pointer text-white w-[90%] mx-auto px-4 py-3 rounded-xl " +
              cn([
                {
                  " blur-sm ": exec !== 0 && exec !== 2,
                },
              ])
            }
          >
            {" "}
            <span> MetaMask
            <p className="text-[10px] text-left text-sky-500 m-0 ">
            Connect MetaMask
          </p>
               </span>
            {exec === 2 ? (
              <i className="bi-disc self-center animate-spin text-xl text-white">
                {" "}
              </i>
            ) : (
              <img
                src="/wallet/metamask.png"
                height={32}
                width={32}
                layout="fixed"
                alt="metamask"
              />
            )}
          </div>
        </div>

        <p
          style={{
            fontFamily: "Poppins",
          }}
          className="p-4 mt-6 text-sm  text-black/70 text-center"
        >
          New to blockchains and binance smartchain?
          <a
            href="/"
            className="text-sky-500 hover:text-[#011D3C] cursor-pointer px-2"
          >
            Learn here
          </a>
        </p>
      </div>
    </>
  );
}

ConnectWalletPopup.propTypes = {};

export default ConnectWalletPopup;
