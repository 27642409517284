import React from "react";
import { Feature } from "../../components";
import "./tokenSaleDetails.css";

const TokenSaleDetails = () => {
  const tokenForSale = 10000000; //This is in case you'd be getting the data from the backend
  const softCap = 50000; //This is in case you'd be getting the data from the backend
  const hardCap = 100000; //This is in case you'd be getting the data from the backend
  const tokenSupply = 500000000; //This is in case you'd be getting the data from the backend
  const briefTokenDetailList = [
    { title: "Token Name", value: "Safe Protocol token" },
    { title: "Token Ticker", value: "$SFP" },
    { title: "Token Type", value: "Bep20" },
    { title: "Token Supply", value: tokenSupply.toLocaleString() },
  ];
  return (
    <div className="id__positioning" id="blog">
      <div className="section__padding">
        <div className="gp-card-bg gpt3__tokenSaleDetails">
          <div className="gpt3__tokenSaleDetails-header">
            <h1 className="gradient__text">Token Sale Details</h1>
            <p>Details about the token sales</p>
          </div>
          <div className="gpt3__tokenSaleDetails-container">
            <div className="gpt3__tokenSaleDetails-list gp-d-lg">
              {briefTokenDetailList.map((item) => (
                <Feature
                  title={item.title}
                  text={item.value}
                  key={item.title}
                />
              ))}
            </div>
            <div className="gpt3__tokenSaleDetails-list">
              <Feature
                title="Token for Sale"
                text={`${tokenForSale.toLocaleString()} SFP`}
              />
              <Feature title="Soft Cap" text={`$${softCap.toLocaleString()}`} />
              <Feature title="Hard Cap" text={`$${hardCap.toLocaleString()}`} />
              <Feature title="Token Distribution" text="After token Presale" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenSaleDetails;
