import React from "react";

import { Possibility, Features, About, Header } from "../containers";
import {Brand } from "../components";

const App = () => {
  return (
    <div>
      <div className="App">
        <div className="gradient__bg pb-8">
          <Header />
        </div>
        <Brand />
        <About />
        <Features />
        <Possibility />
      </div>
    </div>
  );
};

export default App;
