import React from "react";

function ConfirmPopup({ payload, dispatch}) {
 
  const {
    title = "Action Review",
    level = "info",
    body = "Are you sure?",
    onAccept,
    onReject,
    acceptText = "Continue",
    rejectText = "Cancel",
  } = payload || {};

  const levelToColor = {
    info: "text-blue-500",
    error: "text-red-500",
    warning: "text-yellow-500",
    success: "text-[#6222F5]",
  };

  function handleAccept() {
    onAccept();
    close();
  }

  function close(){

    dispatch({ type: "HIDE_CONFIRMATION_POPUP", payload: { infoObject: null } })

  }

  function handleReject() {
    if(onReject){

      onReject();
    }
    close();
  }
  return (
    <>
      <div className="fixed   md:block top-0 left-0 z-10 right-0 bottom-0 bg-black/90  ">
        {/* <i
          onClick={close}
          className="bi-x bg-white cursor-pointer text-3xl  relative top-[5%] md:top-[2%] left-[80%] md:left-[95%] text-black rounded-full shadow-lg shadow-white/30 border border-black/5 px-[6px] py-[1px]"
        ></i> */}
      </div>

      <div className="fixed w-[85%] px-4 max-w-sm flex flex-col justify-center mx-auto left-0 right-0 top-48 md:top-48 z-20 rounded-xl shadow  min-h-[250px]  bg-white py-2">
        <p
          style={{
            fontFamily: "Manrope",
          }}
          className="font-bold text-base  md:text-2xl py-2  text-center "
        >
          {title}
        </p>

        <hr className="block mb-4" />

        <p
          style={{
            fontFamily: "Manrope",
          }}
          className={
            " text-base font-normal pb-4 text-left " + levelToColor[level]
          }
        >
          {body}
        </p>

        <hr className="block mb-2" />

        <div className="flex  flex-row justify-around items-center mt-6 w-[100%] space-x-6">
          <div
            onClick={handleAccept}
            className={
              " cursor-pointer transform hover:scale-105 border border-black flex-1 text-center font-bold bg-white text-black mx-auto px-2 py-2 rounded-xl"
            }
          >
             {acceptText} 
          </div>

          <div
            onClick={handleReject}
            className={
              " cursor-pointer transform  text-center  border border-black hover:scale-105 flex-1 font-bold bg-black text-white mx-auto px-2 py-2 rounded-xl"
            }
          >
             {rejectText} 
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmPopup;
