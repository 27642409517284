export default function walletReducer(state, action) {
    switch (action.type) {
      case "SET_W3":
        return { ...state, ...action.payload.w3 };
        break;
  
      case "CLEAR_W3":
        return null;
        break;
  
      default:
        return state;
    }
  }
  