const config = {
  openLoginClientId: process.env.NEXT_PUBLIC_OPENLOGIN_ID,
  torusClientId: process.env.NEXT_PUBLIC_TORUS_ID,

  wcProjectId: process.env.REACT_APP_WC_PROJECT_ID,
  constants: {
    serviceFeePercentage: 0.025,
    maxServiceFee: 1,
  },

  launchTime: new Date("September 25, 2022 0:0:0").getTime(),

  presaleContract: {
    mainnet:
      process.env.REACT_APP_CA || "0xf258ab98dab12a9601e644510bc45e27ae7ab917",
    testnet:
      process.env.REACT_APP_CA || "0xf258ab98dab12a9601e644510bc45e27ae7ab917",
  },

  bscscanAPIkey: "G7Q4MXFFICNDXHXDSV4KKQBVJ67A8MTK3W",

  siteUrl:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:3000"
      : "https://www.safeprotocol.co",

  nomicAPIKey: process.env.NEXT_PUBLIC_NOMIC_API_KEY,

  web3Providers: {
    ethTestNet: {
      url: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      chainId: 3,
    },
    ethMainNet: {
      url: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
      chainId: 1,
    },
    bscTestNet: {
      url: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      chainId: 97,
    },
    bscMainNet: {
      url: "https://bsc-dataseed.binance.org/",
      chainId: 56,
    },

    ganache: {
      url: "http://127.0.0.1:5000",
      chainId: 5000,
    },
  },

  errorCodes: {
    E001: "You bidded for this item already",
    E002: "This item is not available anymore.",
    E003: "This item is not available for purchase anymore.",
  },

  launchpadPoolFee: 0.00002,

  infuraId: process.env.REACT_APP_INFURA_ID,

  infuraEthMainRpc: process.env.REACT_APP_INFURA_ETH_MAINNET,

  infuraEthRopstenRpc: process.env.REACT_APP_INFURA_ETH_ROPSTEN,

  ipfsAccessPoint: process.env.REACT_APP_IPFS_ACCESS_POINT,

  chains: {
    1: {
      logo: "/wallet/ethereum.png",
      symbol: "ETH",
      name: "Ethereum",
    },

    3: {
      logo: "/wallet/ethereum.png",
      symbol: "ETH(r)",
      name: "Ethereum (ropsten)",
    },

    1337: {
      symbol: "GAN",
      name: "Ganache",
      logo: "/wallet/ganache-logo-dark.svg",
    },

    0: {
      logo: "/wallet/4.png",
      symbol: "UNKNOWN",
      name: "Unkown",
    },
  },
};
export default config;
