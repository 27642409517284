
export default function uiReducer(state, action) {

  switch (action.type) {

    case 'SHOW_CONNECT_WALLET_POPUP': return { ...state, showConnectWalletPopup: true };
      break;

    case 'HIDE_CONNECT_WALLET_POPUP': return { ...state, showConnectWalletPopup: false };
      break;


    case 'SHOW_SIGN_MESSAGE_POPUP': return { ...state, showSignMessagePopup: true };
      break;

    case 'HIDE_SIGN_MESSAGE_POPUP': return { ...state, showSignMessagePopup: false };
      break;


    case 'SHOW_INFO_POPUP': return { ...state, infoPopup: { show: true, infoObject: action.payload.infoObject } };
      break;

    case 'HIDE_INFO_POPUP': return { ...state, infoPopup: { show: false, infoObject: null } };
      break;


    case 'SHOW_CONFIRMATION_POPUP': return { ...state, confirmationPopup: { show: true, infoObject: action.payload.infoObject } };
      break;

    case 'HIDE_CONFIRMATION_POPUP': return { ...state, confirmationPopup: { show: false, infoObject: null } };
      break;

    case 'SHOW_USER_PANEL_POPUP': return { ...state, showUserPanelPopup: true };
      break;

    case 'HIDE_USER_PANEL_POPUP': return { ...state, showUserPanelPopup: false };
      break;

    case 'SHOW_X_LOADER': return { ...state, xLoader: true };
      break;

    case 'HIDE_X_LOADER': return { ...state, xLoader: false };
      break;

    case 'SHOW_NET_LOADER': return { ...state, netLoader: true };
      break;

    case 'HIDE_NET_LOADER': return { ...state, netLoader: false };
      break;

    case 'SHOW_MOBILE_NAV': return { ...state, mobileNav: true };
      break;

    case 'HIDE_MOBILE_NAV': return { ...state, mobileNav: false };
      break;

    case 'SET_MQTT': return { ...state, mqtt: action.payload.mqtt };
      break;

    case 'CLEAR_MQTT': return { ...state, mqtt: null };
      break;

    default: return state;







  }

}