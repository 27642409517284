import React from "react";
import "./buyToken.css";
import { BuyTokensForm } from "../../forms";

const BuyToken = () => {
  return (
    <div className="padding__top h-100">
      <div className="gpt3__buyToken section__padding">
        <div className="gpt3__buyToken-content">
          <h1 className="gradient__text">Join Safe Protocol Presale Round 1</h1>
          <p>How to buy:</p>
          <ul className="gpt3__buyToken-content__list">
            <li>1. Connect your wallet to the BNB Chain.</li>
            <li>
              2. Ensure you have enough BNB token to cover Token and gas fees.
            </li>
            <li>3. Input amount you want to buy and Click on buy.</li>
          </ul>
          <div className="gpt3__buyToken-content__highlights">
            <p>
              <span className="gradient__tex">$0.015</span>
              <span className="gradient__tex">Price per token</span>
            </p>
            <p>
              <span className="gradient__tex">$30</span>
              <span className="gradient__tex">Min. Investment</span>
            </p>
          </div>
        </div>
        <div className="gpt3__buyToken-form">
          <BuyTokensForm />
        </div>
      </div>
    </div>
  );
};

export default BuyToken;
