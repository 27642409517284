// import useFetch from "../hooks/useFetch";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import detectEthereumProvider from "@metamask/detect-provider";
import config from "./config";
import Web3 from "web3";

export const networkIdToNetworkInfoMap = {
  1: { name: "Ethereum", coin: "ETH" },
  3: { name: "Ropsten", coin: "rETH" },
  56: { name: "Binance Smartchain", coin: "BNB" },
  137: { name: "Polygon", coin: "MATIC" },
  25: { name: "Cronos", coin: "CRO" },
  97: { name: "Binance Smartchain Testnet", coin: "tBNB" },
  42220: { name: "Celo", coin: "CELO" },
  80001: { name: "Polygon Mumbai", coin: "MATIC" },
};

export function metamaskHandleChainChanged(dispatch) {
  return () => {
    dispatch({ type: "CLEAR_W3" });
    dispatch({ type: "CLEAR_USER" });
  };
}

export function metamaskHandleAccountsChanged(dispatch) {
  return () => {};
}

export function metamaskHandleDisconnect(dispatch, provider) {
  return async () => {
    // await provider.engine.stop();
  };
}

export async function signMessage(state, messageHash) {
  const web3 = state.w3.web3;
  const address = state.w3.address;

  try {
    // console.log( messageHash )
    // console.log(address)
    const signature = await web3.eth.personal.sign(
      web3.utils.utf8ToHex(messageHash),
      address,
      process.env.REACT_APP_PASSWORD || "password"
    );

    return signature;
  } catch (err) {
    console.log("Error signing message - " + err.message);

    return;
  }
}

export async function fetchUser(address, networkId, messageHash, signature) {
  // console.log(address, networkId,signature)
  //   const res = await useFetch({
  //     url: urls.createOrGetUser,
  //     method: "post",
  //     data: { address, networkId, signature, messageHash },
  //   });
  //   if (!res.success) {
  //     console.log("Error at fetchUser -> ", res.errorMessage);
  //   }
  //   return res;
}

export async function assertChainIsSmartchainOrAdd(ethereum) {
  const smartchainId = "0x38";
  // console.log(chainId, smartchainId)

  try {
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: smartchainId }],
    });
    return true;
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: smartchainId,
              chainName: "Binance Smartchain Mainnet",
              blockExplorerUrls: ["https://bscscan.com"],
              rpcUrls: [config.web3Providers.bscMainNet.url],
              nativeCurrency: {
                symbol: "BNB",
                decimals: 18,
                name: "Smartchain",
              },
            },
          ],
        });
        return true;
      } catch (addError) {
        // handle "add" error
        return false;
      }
    }
    // handle other "switch" errors
    return false;
  }
}

// CONNECT VIA WALLETCONNECT

export async function connectViaWalletconnect(dispatch) {
  dispatch({ type: "SHOW_X_LOADER" });

  //   if (window.localStorage && localStorage.getItem("walletconnect")) {
  //     localStorage.removeItem("walletconnect");
  //   }

  // console.log(config.infuraEthMainRpc)
  //   console.log("PROJECT ID:", config.wcProjectId);
  const provider = await EthereumProvider.init({
    projectId: config.wcProjectId,
    // rpcMap: {
    //   1: config.infuraEthMainRpc,
    56: "https://bsc-dataseed.binance.org",
    97: "https://data-seed-prebsc-1-s2.binance.org:8545",
    // },
    chains: [56, 97],
    showQrModal: true,
  });

  try {
    await provider.enable();

    // Events

    provider.on("accountsChanged", async (accounts) => {
      // console.log(accounts);
    });

    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      dispatch({ type: "CLEAR_USER" });
      dispatch({ type: "CLEAR_W3" });
      // console.log(chainId);
    });

    const web3 = new Web3(provider);

    let accounts = await web3.eth.getAccounts();
    if (accounts.length === 0) {
      console.log("Allow connection to your wallet");
    } else {
      let chainId = await web3.eth.getChainId();
      const chains = ["0x61", "0x38"];

      const account = accounts[0];
      const balance = web3.utils.fromWei(await web3.eth.getBalance(account));

      const w3 = {
        address: account,
        balance,
        chainId,
        networkId: web3.utils.hexToNumber(chainId),
        web3,
        provider,
        connectionChannel: "WALLETCONNECT",
      };
      //   console.log("CONNECTED : ", w3);
      dispatch({ type: "SET_W3", payload: { w3 } });
    }

    dispatch({ type: "HIDE_X_LOADER" });
    dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
    dispatch({ type: "SHOW_SIGN_MESSAGE_POPUP" });
  } catch (err) {
    console.log("Unable to connect to wallet, please try again.");

    dispatch({ type: "HIDE_X_LOADER" });
    // dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
  }
}

// CONNECT VIA METAMASK

export async function connectViaMetamask(dispatch) {
  const provider = await detectEthereumProvider();

  dispatch({ type: "SHOW_X_LOADER" });

  if (!provider) {
    console.log("No metmask");

    window.location.replace("https://metamask.app.link/dapp/safeprotocol.co");
    return;
  }

  if (provider !== window.ethereum) {
    console.log(
      "It seems you have multiple wallets installed, please use Metamask only and try again."
    );

    return;
  }
  // Events

  provider.on(
    "accountsChanged",
    metamaskHandleAccountsChanged(dispatch, provider)
  );

  // Subscribe to chainId change
  provider.on("chainChanged", metamaskHandleChainChanged(dispatch));

  // Subscribe to session disconnection
  provider.on("disconnect", metamaskHandleDisconnect(dispatch));

  try {
    let accounts = await provider.request({
      method: "eth_requestAccounts",
    });

    if (accounts.length === 0) {
      console.log("Please allow connection to Metamask!");
      // dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
    } else {
      let chainId = await provider.request({ method: "eth_chainId" });

      const chains = ["0x61", "0x38"];

      const account = accounts[0];
      const web3 = new Web3(provider);
      const balance = web3.utils.fromWei(await web3.eth.getBalance(account));

      const w3 = {
        address: account,
        balance,
        chainId,
        networkId: web3.utils.hexToNumber(chainId),
        web3,
        provider,
        connectionChannel: "METAMASK",
      };
      //   console.log("CONNECTED : ", w3);
      dispatch({ type: "SET_W3", payload: { w3 } });
      dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
      dispatch({ type: "SHOW_SIGN_MESSAGE_POPUP" });
    }

    dispatch({ type: "HIDE_X_LOADER" });
  } catch (err) {
    if (err.code === 4001) {
      console.log("Please allow connection to Metamask!");
    } else {
      console.log("Unable to connect to Metamask!");

      console.error(err.message);
    }
    dispatch({ type: "HIDE_X_LOADER" });
    // dispatch({ type: "HIDE_CONNECT_WALLET_POPUP" });
  }
}
